$(document).ready(function() {
  (function($, Foundation) {
    // Show modal
    $('.js-switch-to-this-pathway-modal').on('click', function(e) {
      e.preventDefault()
      // $('.reveal-overlay').remove()
      var modal = new Foundation.Reveal($('#modalSwitchPathway'))

      // Register our handler to close the modal
      $('#modalSwitchPathway .modal__close')
        .off('click')
        .on('click', function() {
          modal.close()
        })

      // Destroy our modal once it is closed.
      // If we don't do this, we end up with problems on repeated openings.
      $(window)
        .off('closed.zf.reveal')
        .on('closed.zf.reveal', function() {
          // We need a set timeout so that we end up behind the
          // this.$element.hide call in the reveal plugin close method.
          setTimeout(function() {
            modal.destroy()
            document.activeElement.blur()
          }, 0)
        })

      // Open the modal
      modal.open()
    })
  })(window.jQuery, window.Foundation)
})
