$(document).ready(function () {
  (function ($, Cookies) {
    $(".js-dont-show-first-time").on("click", function (event) {
      event.stopPropagation();
      event.preventDefault();

      Cookies.set("dont-show-first-time", true, { expires: 365 });

      var href = $(this).attr("href");
      document.location.href = href;
    });

    $(".js-dont-show-pathway").on("click", function (event) {
      event.stopPropagation();
      event.preventDefault();

      Cookies.set("dont-show-pathway", true, { expires: 365 });
      Cookies.set("dont-show-first-time", true, { expires: 365 });

      var href = $(this).attr("href");
      document.location.href = href;
    });
  })(window.jQuery, window.Cookies);
});
