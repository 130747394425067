$(document).ready(function () {
  (function ($) {
    // Only capture state for logged-in users
    if (!window.state || !window.state.isLoggedIn) {
      return
    }

    var id = window.entry && window.entry.id

    if (!id) {
      return
    }

    // Save the state of navigation so that we can report this
    // back to the user on future page loads.
    var $nav = $('.nav-links__previous, .nav-links__next')
    var path = document.location.pathname
    var states = {}

    states[path] = {
      name: 'navigation',
      referrer: document.referrer,
      value: 'arrived'
    }

    // Save that the user has arrived on a page
    saveState('arrived:' + id, states)

    function throttle(fn, wait) {
      var time = Date.now()
      return function () {
        if ((time + wait - Date.now()) < 0) {
          fn()
          time = Date.now()
        }
      }
    }

    $('.js-prevnext__mark-as-completed-button').on('click', function (event) {
      $(this).closest('.js-prevnext__mark-as-completed').hide()
      $('.js-prevnext__mark-as-uncompleted').show()
      states[path] = {
        name: 'navigation',
        referrer: document.referrer,
        value: 'bottom'
      }

      saveState('bottom:' + id, states)

      // Save this entry state against the pathway it belongs to so we can
      // later calculate the amount of time in the pathway the user has completed
      var pathwaysState = window.state && window.state.pathways || {}
      var pathwayId = $(this).data('pathway-id')
      var readingTime = $(this).data('reading-time')
      if (pathwayId && readingTime) {
        var pathwayState = pathwaysState[pathwayId] || {}
        pathwayState.readingTime = pathwayState.readingTime || {}
        pathwayState.readingTime[id] = {
          name: 'readingTime',
          id: id,
          value: readingTime
        }

        pathwayState.completed = pathwayState.completed || {}
        pathwayState.completed[id] = {
          name: 'completed',
          id: id,
          value: Date.now()
        }

        pathwaysState[pathwayId] = pathwayState
        saveState('pathways', pathwaysState)
      }
    })

    $('.js-prevnext__mark-as-uncompleted-button').on('click', function () {
      $(this).closest('.js-prevnext__mark-as-uncompleted').hide()
      $('.js-prevnext__mark-as-completed').show()

      deleteState('bottom:' + id)

      // Delete this entry state from the pathway it belongs to so we can
      // later calculate the amount of time in the pathway the user has completed
      var pathwayId = $(this).data('pathway-id')
      if (pathwayId) {
        var pathwaysState = window.state && window.state.pathways || {}
        var pathwayState = pathwaysState[pathwayId] || {}
        Object.keys(pathwayState).forEach(function (key) {
          if (key === ('readingTime' || 'completed')) {
            Object.keys(pathwayState[key]).forEach(function (entryKey) {
              delete (pathwayState[entryKey])
            })
          }
        })

        pathwaysState[pathwayId] = pathwayState
        saveState('pathways', pathwaysState)
      }
    })

    // Save that the user has exited a page
    $nav.on('click', function () {
      var states = {}

      states[path] = {
        name: 'navigation',
        referrer: document.referrer,
        value: 'exited'
      }

      saveState('exited:' + id, states)
    })
  }(window.jQuery))
})

$(document).ready(function () {
  (function ($) {
    $('.js-collection-list__kt-progress').on('click', function () {
      var states = {}
      var id = $(this).data('entryId')
      var url = $(this).data('entryUrl')
      var previouslyAttended = $(this).hasClass('collection-list__progress--attended') || false
      var eventsState = window.state && window.state.events || {}
      var deltaAttendedOrWatched = 0

      var $watchandlearnHero = $(this)
        .closest('.collection-list__item')
        .find('.watchandlearn__hero')

      var videoHasBeenWatched = $watchandlearnHero
        .hasClass('watchandlearn__hero--overlaid-watched')

      var $attendanceItem = $('.js-attendance-item[data-id="' + id + '"] .js-activity-box-attendance')
      var attendanceItemClicked = false
      var path = $(this).data('entryPath')
      var $progressBar = $(this).closest('.js-collection-name')
      var $progressBarCompleted = $progressBar.find('.js-progress-bar__completed')
      var $progressBarLabel = $progressBar.find('.js-progress-bar__label')
      var active = $progressBarCompleted.data('active')
      var total = $progressBarCompleted.data('total')
      var progress

      states[path] = {
        name: 'navigation',
        referrer: document.referrer,
        value: 'bottom'
      }

      if (previouslyAttended) {
        // Remove the attended entry from the events state.
        Object.keys(eventsState).forEach(function (key) {
          var item = eventsState[key]

          if (+key === +id && item.name === 'attended') {
            delete eventsState[key]
          }
        })

        $(this).empty()

        saveState('events', eventsState)

        if (!videoHasBeenWatched) {
          deltaAttendedOrWatched--
        }

        $watchandlearnHero
          .removeClass('watchandlearn__hero--overlaid-attended')
          .find('.watchandlearn__progress-container')
          .removeClass('watchandlearn__progress-container--overlay-attended')

        if (videoHasBeenWatched) {
          $watchandlearnHero
            .find('.watchandlearn__progress-container')
            .addClass('watchandlearn__progress-container--overlay-watched')

          $(this)
            .addClass('collection-list__progress--watched')

          $(this).closest('.watchandlearn__progress-box')
            .find('.watchandlearn__progress-box-caption')
            .html('Watched')
        } else {
          $(this)
            .removeClass('collection-list__progress--watched')

          $(this).closest('.watchandlearn__progress-box')
            .find('.watchandlearn__progress-box-caption')
            .html('Record your attendance')
        }

        $(this).toggleClass('collection-list__progress--attended')

        if ($attendanceItem.hasClass('activity-box--populated-active')) {
          $attendanceItem.click()
          attendanceItemClicked = true
        }
      } else {
        saveState('bottom:' + id, states)
        $(this).html('<i class="icon-ok"></i>')

        // Add the attended entry to the event state.
        eventsState[id] = {
          name: 'attended',
          page: url,
          value: 'dashboard'
        }

        if (!videoHasBeenWatched) {
          deltaAttendedOrWatched++
        }

        saveState('events', eventsState)

        $(this)
          .closest('.collection-list__item')
          .find('.watchandlearn__hero')
          .addClass('watchandlearn__hero--overlaid-attended')
          .find('.watchandlearn__progress-container')
          .addClass('watchandlearn__progress-container--overlay-attended')

        $(this)
          .removeClass('collection-list__progress--watched')

        $(this).closest('.watchandlearn__progress-box')
          .find('.watchandlearn__progress-box-caption')
          .html('Attended')

        $(this).toggleClass('collection-list__progress--attended')

        if (!$attendanceItem.hasClass('activity-box--populated-active')) {
          $attendanceItem.click()
          attendanceItemClicked = true
        }
      }

      $progressBarCompleted.data('active', active + deltaAttendedOrWatched)
      progress = parseInt((active + deltaAttendedOrWatched) / total * 100, 10) + '%'
      $progressBarCompleted.css('width', progress)
      $progressBarLabel.html(progress)

      if (attendanceItemClicked) {
        updateCircle(deltaAttendedOrWatched)
      }
    })
  }(window.jQuery))
})
