$(document).ready(function () {
  (function ($) {
    // Add ids to our h2s in the body
    $('.article__body h2').each(function (index, element) {
      element.id = element.innerText.toLowerCase().replace(/ /g, '-')
    })

    // link the .sidebox__headings to the h2s
    $('.sidebox.sidebox--outline .sidebox__headings h2').each(function (index, element) {
      var id = element.innerText.toLowerCase().replace(/ /g, '-')
      $(element).wrap('<a href="#' + id + '"></a>')
    })

    function throttle(fn, wait) {
      var time = Date.now()
      return function() {
        if ((time + wait - Date.now()) < 0) {
          fn();
          time = Date.now()
        }
      }
    }

    var $sidebox = $('.js-sidebox-outline-container')
    var $header = $('#header')
    var $footer = $('#footer')
    var height = $header.height()

    if ($sidebox.length) {
      var sideboxOrigOffsetTop = $sidebox.offset().top
      var windowOffsetTopToMakeSideboxStatic

      // Set the width concretely so we retain it when going position:fixed
      $sidebox.width($sidebox.width())

      // only fix the outline box on scroll underneath the header
      // when we're on desktop
      if (window.innerWidth >= 850) {
        window.addEventListener('scroll', throttle(function () {
          // If the user has scrolled down to the start of the sidebox, make it fixed
          if ($sidebox.offset().top - $(document).scrollTop() <= height) {
            if ($sidebox.css('position') === 'fixed' && $sidebox.offset().top <= sideboxOrigOffsetTop) {
              $sidebox.css({ position: 'static' })
            } else {
              // If the user has scrolled down to near the bottom of the page, such that the fixed sidebox
              // will interfere with the footer, make it static
              if ($sidebox.css('position') === 'fixed' && $sidebox.offset().top > ($(document).height() - $footer.outerHeight() - $sidebox.height())) {
                windowOffsetTopToMakeSideboxStatic = windowOffsetTopToMakeSideboxStatic || $(window).scrollTop()
                $sidebox.css({ position: 'static' })
              } else {
                if ($sidebox.css('position') === 'static' && (!windowOffsetTopToMakeSideboxStatic || ($(window).scrollTop() + 100) < windowOffsetTopToMakeSideboxStatic)) {
                  $sidebox.css({ position: 'fixed', top: height + 'px' })
                }
              }
            }
          } else {
            $sidebox.css({ position: 'static' })
          }
        }, 50))
      }
    }

    $('.js-back-to-top').on('click', function() {
      $('html, body').animate({
        scrollTop: 0
      }, 600)
      return false
    })

  }(window.jQuery))
})
