$(document).ready(function() {
  ;(function($) {
    // Hide "show more" button if there's no more to show
    if (!$('.profile-block').length || $('.profile-block').last().data('last')) {
      $('.js-button-show-more').hide()
    }

    var showingMore = false;
    $('.js-button-show-more').on('click', function() {
      if (showingMore) {
        return;
      }

      showingMore = true;
      var filters = []
      var $filterBar = $('.filter-bar')
      var $showMoreButton = $(this)

      // Start the spinner
      $showMoreButton.spin({
        lines: 13,
        length: 20,
        width: 2,
        radius: 30,
        corners: 1,
        rotate: 0,
        direction: 1,
        color: '#e10a09',
        speed: 1,
        trail: 60,
        shadow: false,
        hwaccel: false,
        className: 'spinner',
        zIndex: 2e9,
        top: '50%',
        left: '50%',
      })

      $filterBar.find('input:checked').each(function() {
        filters.push($(this).data('filter'))
      })

      var id = $filterBar.data('id')
      var offset = $filterBar.data('offset')
      var limit = $filterBar.data('limit')
      var filterQuery = filters.length ? '?filters=' + filters.join('|') : ''

      var queryString;
      if (document.location.search) {
        queryString = document.location.search + '&id=' + id + '&offset=' + offset + '&limit=' + limit
      } else {
        queryString = filterQuery
          ? filterQuery + '&id=' + id + '&offset=' + offset + '&limit=' + limit
          : '?id=' + id + '&offset=' + offset + '&limit=' + limit
      }

      var url = '/profiles/query' + queryString

      $.ajax({
        url: url,
      }).done(function(data) {
        var $element = $('.profile-blocks')

        // Strip out the injected raven scripts
        data = data.replace(/<script [\s\S]*?<\/script>/g, '')

        // append the new data to the container
        $element.append(data)

        // increment the offset, ready for the next button push
        $filterBar.data('offset', offset + limit)

        // Kill the spinner
        $showMoreButton.spin(false)

        // Hide "show more" button if there's no more to show
        if (!$('.profile-block').length || $('.profile-block').last().data('last')) {
          $('.js-button-show-more').hide()
        }

        showingMore = false;
      })
    })
  })(window.jQuery)
})
