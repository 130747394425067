$(document).ready(function(){
    (function($) {
        // On the contact form, show the expert community text
        // only when the expert community tab is active,
        // and hide contact address.
        $('#contact-tabs').on('change.zf.tabs', function(){
            var id = $(this).find('.is-active a').attr('id');
            if (id == 'tab-expert') {
                $('.expertCommunityContactBlurb').removeClass('hide');
                $('.contactAddress').addClass('hide');
            } else {
                $('.expertCommunityContactBlurb').addClass('hide');
                $('.contactAddress').removeClass('hide');
            }
        })
    }(window.jQuery));
})
