$(document).ready(function () {
  (function ($) {
    $('.js-switch-to-this-pathway').on('click', function (event) {
      event.stopPropagation()
      event.preventDefault()

      var pathwayId = $(this).data('pathwayId')
      var pathwayNextEntryId = $(this).data('pathwayNextEntryId')

      if (pathwayId) {
        var pathwaysState = window.state && window.state.pathways || {}
        var pathwayState = pathwaysState[pathwayId] || {}
        pathwayState.next = {
          name: 'next',
          value: pathwayNextEntryId
        }

        pathwaysState[pathwayId] = pathwayState
        saveState('pathways', pathwaysState)

        var states = {
          name: 'pathwayCurrent', value: pathwayId
        }
        var href = $(this).attr('href')
        saveState(
          'pathwayCurrent',
          states,
          function() {
            document.location.href = href
          })
      }
    })
  }(window.jQuery))
})
