$(document).ready(function(){
    (function($) {
        // Populate the login form name field from sessionStorage,
        // presuming we've been rejected by the back end.
        if ($('.login-form *[name=loginName]').length
            && ! $('.login-form *[name=loginName]').val()
            && sessionStorage.loginName) {
            $('.login-form *[name=loginName]').val(sessionStorage.loginName)
        }

        $('.login-form *[name=loginName]').on('change', function() {
            sessionStorage.loginName = $(this).val();
        });
    }(window.jQuery));
})
