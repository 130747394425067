$(document).ready(function () {
  (function ($) {
    var prev = 0
    var $window = $(window)
    var nav = $('.header')

    $window.on('scroll', function () {
      var scrollTop = $window.scrollTop()
      nav.toggleClass('header--hidden', scrollTop > prev)
      prev = scrollTop
    })
  }(window.jQuery))
})
