$(document).ready(function() {
  ;(function($) {
    function reload() {
      var filters = []
      var $filterBar = $('.filter-bar')

      // Start the spinner
      $filterBar.spin({
        lines: 13,
        length: 20,
        width: 2,
        radius: 30,
        corners: 1,
        rotate: 0,
        direction: 1,
        color: '#e10a09',
        speed: 1,
        trail: 60,
        shadow: false,
        hwaccel: false,
        className: 'spinner',
        zIndex: 2e9,
        top: '50%',
        left: '50%',
      })

      $filterBar.find('input:checked').each(function() {
        filters.push($(this).data('filter'))
      })

      var id = $filterBar.data('id')
      var offset = 0
      var limit = $filterBar.data('limit')
      var filterQuery = filters.length ? '?filters=' + filters.join('|') : ''

      $filterBar.find('.js-areas-of-expertise[data-selected]').each(function() {
        filterQuery = filterQuery
          ? filterQuery + '&area=' + $(this).data('slug')
          : '?area=' + $(this).data('slug')
      })

      var queryString = filterQuery
        ? filterQuery + '&id=' + id + '&offset=' + offset + '&limit=' + limit
        : '?id=' + id + '&offset=' + offset + '&limit=' + limit

      var url = '/profiles/query' + queryString

      $.ajax({
        url: url,
      }).done(function(data) {
        var $element = $('.profile-blocks')

        // Strip out the injected raven scripts
        data = data.replace(/<script [\s\S]*?<\/script>/g, '')

        // append the new data to the container
        $element.empty().append(data)

        // increment the offset, ready for the next button push
        $filterBar.data('offset', offset + limit)

        // Update our history
        if (filterQuery) {
          window.history.pushState('', '', filterQuery)
        } else {
          window.history.pushState('', '', '?')
        }

        // Kill the spinner
        $filterBar.spin(false)

        // Hide "show more" button if there's no more to show
        if (
          !$('.profile-block').length ||
          $('.profile-block')
            .last()
            .data('last')
        ) {
          $('.js-button-show-more').hide()
        } else {
          $('.js-button-show-more').show()
        }
      })
    }

    $('.filter-bar__panel:not(.filter-bar__panel--menu)').on('click', function(
      element
    ) {
      if (element.target.className !== 'filter-bar__input') {
        $input = $(this).find('input')
        $input.prop('checked', !$input.prop('checked'))
      }

      // Remove any previous areas of expertise selections
      $('.js-areas-of-expertise[data-selected]').each(function(element) {
        this.removeAttribute('data-selected')
      })

      reload()
    })

    $('.js-areas-of-expertise').on('click', function(event) {
      event.stopPropagation()

      var slug = event.target.getAttribute('data-slug')

      // Remove any previous areas of expertise selections
      $('.js-areas-of-expertise[data-selected]').each(function(element) {
        this.removeAttribute('data-selected')
      })

      // Select the current areas of expertise
      event.target.setAttribute('data-selected', true)

      reload()
    })
  })(window.jQuery)
})
