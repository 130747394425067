function updateCircle(deltaAttendedOrWatched) {
  var $elementTotalAttendedOrWatched = $('.js-progress-circle-watched-total')
  var $elementTotalNotAttendedOrWatched = $('.js-progress-circle-unwatched-total')
  var $circleContainer = $('.js-progress-circle-container')
  var totalAttendedOrWatched = +$elementTotalAttendedOrWatched.html() + deltaAttendedOrWatched
  var totalNotAttendedOrWatched = +$elementTotalNotAttendedOrWatched.html() - deltaAttendedOrWatched

  if (totalAttendedOrWatched) {
    $elementTotalAttendedOrWatched.addClass('activity-box--populated-active').html(totalAttendedOrWatched)
  } else {
    $elementTotalAttendedOrWatched.removeClass('activity-box--populated-active').html(totalAttendedOrWatched)
  }

  $circleContainer.attr('data-total', totalAttendedOrWatched)
  $elementTotalNotAttendedOrWatched.html(totalNotAttendedOrWatched)
}

function parseState(name, value) {
  try {
    var state = JSON.parse(value.replace(/&quot;/g, '"'))

    // store the state on the global
    window.state = window.state || {}
    window.state[name] = state

    return state
  } catch (error) {
    console.log('failed to parse state %s: %s', name, value)

    // rethrow an error for sentry
    throw error
  }
}

function getState(stateName, callback) {
  var $form = $('#statehelper-form')

  if (!$form) {
    console.log('Could not get state as could not find #statehelper-form')
    return
  }

  window.state = window.state || {}
  delete window.state[stateName]

  $form.find("[name='action']").val('statehelper/statehelper/get-state')
  $form.find("[name='name']").val(stateName)
  $form.find("[name='value']").val('')

  $.ajax({
    type: 'POST',
    url: '/',
    data: $form.serialize(),
    dataType: 'json',
    success: function (data) {
      callback && callback(data)
      // console.log('data: ', data)
    },
    error: function (jqXHR, e) {
      console.log('failed to get state %s (status: %s)', stateName, jqXHR.status)

      // rethrow an error for sentry
      throw e
    }
  })
}

function saveState(stateName, states, callback) {
  var $form = $('#statehelper-form')

  if (!$form) {
    console.log('Could not save state as could not find #statehelper-form')
    return
  }

  window.state = window.state || {}
  window.state[stateName] = window.state[stateName] || {}

  var payload = window.state[stateName]

  Object.keys(states).forEach(function (stateKey) {
    payload[stateKey] = states[stateKey]
  })

  $form.find("[name='action']").val('statehelper/statehelper/save-state')
  $form.find("[name='name']").val(stateName)
  $form.find("[name='value']").val(JSON.stringify(payload))

  $.ajax({
    type: 'POST',
    url: '/',
    data: $form.serialize(),
    dataType: 'json',
    success: function (data) {
      // console.log('data: ', data)
      if (callback) {
        callback()
      }
    },
    error: function (jqXHR, e) {
      console.log('failed to save state %s (status %s): %s', stateName, jqXHR.status, JSON.stringify(states))

      // rethrow an error for sentry
      throw e
    }
  })
}

function deleteState(stateName) {
  var $form = $('#statehelper-form')

  if (!$form) {
    console.log('Could not delete state as could not find #statehelper-form')
    return
  }

  window.state = window.state || {}
  delete window.state[stateName]

  $form.find("[name='action']").val('statehelper/statehelper/delete-state')
  $form.find("[name='name']").val(stateName)
  $form.find("[name='value']").val('')

  $.ajax({
    type: 'POST',
    url: '/',
    data: $form.serialize(),
    dataType: 'json',
    success: function (data) {
      // console.log('data: ', data)
    },
    error: function (jqXHR, e) {
      console.log('failed to delete state %s (status %s)', stateName, jqXHR.status)

      // rethrow an error for sentry
      throw e
    }
  })
}
