$(document).ready(function() {
  ;(function($) {
    // Ensure links with target=_blank have the symbol to
    // indicate they open in a new tab
    $('a[target="_blank"]:not(:has(.icon-link-ext))')
      .not('.js-logo__link')
      .not('.feed-block')
      .not('.media-object-section__icon')
      .append($('<i class="icon-link-ext"></i>'))

    // Ensure PDF links open in a new tab
    $('a[href$=".pdf"]')
      .not('[target]')
      .attr('target', '_blank')
      .append($('<i class="icon-link-ext"></i>'))

    // Remove accordion menu event handlers and restore link behaviour.
    // This will let collapsed menu sections link to the respective page.
    $('.is-accordion-submenu-parent > a')
      .off('click')
      .on('click', function(event) {
        event.stopPropagation()
        document.location.href = event.target.href
      })
  })(window.jQuery)
})
