$(document).ready(function () {
  (function ($) {
    /***********************************************************************
    * Use the Vimeo video API to control seek behaviour
    ************************************************************************/
    // Get the video element that we wish to control
    var players = $('iframe[src*="player.vimeo.com/video"]');
    // console.log(players)
    var eventMarker = {}

    players.each(function () {
      var player = new Vimeo.Player(this)

      // When a link with a class of "timecode" is clicked, capture the event
      $(this).closest('.js-vimeo').find('.timecode').on('click', function (e) {
        // Prevent the default link (typically a hash) from executing
        e.preventDefault()
        // Get the location value from the HTML5 data parameter
        var seekVal = $(this).attr('data-seek')

        player.setCurrentTime(seekVal)
          .then(function (seconds) {
            // seconds = the actual time that the player seeked to
          }).catch(function (error) {
            switch (error.name) {
            case 'RangeError':
              // the time was less than 0 or greater than the video’s duration
              break

            default:
              // some other error occurred
              break
            }
          })
      })

      var iframeId = $(this).attr('src').replace(/\?.*/, '')

      eventMarker[iframeId] = {
        'videoPlayed': false,
        'videoPaused': false,
        'videoResumed': false,
        'videoSeeking': false,
        'videoCompleted': false,
        'timePercentComplete': 0
      }

      // Define the milestones at which we want to track progress
      var milestones = [10, 20, 30, 40, 50, 60, 70, 75, 80, 90]
      milestones.forEach(function (milestone) {
        eventMarker[iframeId]['progress' + milestone] = false
      })

      player.on('play', function () {
        // console.log('started playing the video!');
      })

      player.on('ended', function () {
        // console.log('finished the video!');
      })

      player.on('timeupdate', function (data) {
        var iframeId = this.element.src.replace(/\?.*/, '')

        // data looks somewhat like: {seconds: 2326.402, percent: 0.522, duration: 4459.76}
        eventMarker[iframeId].timePercentComplete = Math.round((data.percent) * 100)

        // console.log(eventMarker[iframeId].timePercentComplete)

        milestones.forEach(function (milestone) {
          var progressMilestone = 'progress' + milestone
          var states = {}

          if (eventMarker[iframeId].timePercentComplete > milestone && !eventMarker[iframeId][progressMilestone]) {
            eventMarker[iframeId][progressMilestone] = true

            // Save the state of progress so that we can style this video
            // as 'watched' on future page loads.
            states[iframeId] = {
              name: 'progress',
              page: window.location.href,
              url: iframeId,
              value: eventMarker[iframeId].timePercentComplete
            }

            saveState('vimeo', states)

            // Ensure we only end up with the latest entry in the vimeoLastWatched state
            window.state = window.state || {}
            window.state['vimeoLastWatched'] = {}
            saveState('vimeoLastWatched', states)

            if (milestone === 75) {
              var html = '<h3 class="u-no-margin-bottom">Congratulations!</h3>'
              html += '<p><small>This video is added to your Knowledge Transfer Challenge score.</small></p>'
              html += '<p><small>Check your Dashboard to see your progress and record whick Knowledge Transfer events you’ve attended.</small></p>'
              $('.js-logo__body').html(html)

              $('.js-logo__link').html('Watch next video <i class="icon-right-open-big"></i>')

              var url = $('.js-logo__link').data('nextUrl')
              $('.js-logo__link').attr('href', url)
            }
          }
        })
      })
    })
  }(window.jQuery))
})
