$(document).ready(function () {
  (function ($) {
    // Polyfill objectFit for IE11
    // https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3
    // https://github.com/anselmh/object-fit/blob/c6e275b099caf59ca44bfc5cbbaf4c388ace9980/src/polyfill.object-fit.core.js#L396
    if ('objectFit' in document.documentElement.style === false) {
      $('.kt__hero, .kt__hero-videoless, .pathway__hero, .kt-home__hero-videoless, .watchandlearn__hero, .event-media-block__section').each(function () {
        var $container = $(this)
        var imgUrl = $container.find('img').prop('src')
        if (imgUrl) {
          $container
            .css('backgroundImage', 'url(' + imgUrl + ')')
            .addClass('compat-object-fit')
        }
      })
    }
  }(window.jQuery))
})
