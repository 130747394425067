$(document).ready(function () {
  (function ($) {
    $('.js-activity-box-attendance').on('click', function () {
      var states = {}
      var id = $(this).closest('.js-attendance-item').data('id')
      var url = $(this).closest('.js-attendance-item').data('url')
      var path = $(this).closest('.js-attendance-item').data('path')
      var previouslyAttended = $(this).hasClass('activity-box--populated-active') || false
      var eventsState = window.state && window.state.events || {}

      if (previouslyAttended) {
        // Delete the attended state
        Object.keys(eventsState).forEach(function (key) {
          var item = eventsState[key]

          if (+key === +id && item.name === 'attended') {
            delete (eventsState[key])
          }
        })

        saveState('events', eventsState)

        $(this).toggleClass('activity-box--populated-active')

        deleteState('bottom:' + id)
      } else {
        // Save the state of attendance
        eventsState[id] = {
          name: 'attended',
          page: url,
          value: 'dashboard'
        }

        saveState('events', eventsState)

        states[path] = {
          name: 'navigation',
          referrer: document.referrer,
          value: 'bottom'
        }

        saveState('bottom:' + id, states)

        $(this).toggleClass('activity-box--populated-active')
      }
    })

    $('.js-activity-box-video').on('click', function () {
      var url = $(this).closest('.js-attendance-item').data('url')
      var video = $(this).closest('.js-attendance-item').data('video')
      var previouslyWached = $(this).hasClass('activity-box--populated-active') || false
      var eventsState = window.state && window.state.vimeo || {}

      if (previouslyWached) {
        // Delete the watched state
        Object.keys(eventsState).forEach(function (key) {
          var item = eventsState[key]

          if (item.name === 'progress' &&
            /vimeo.com/.test(key) &&
            key.replace(/.*?(\d+)/, '$1') === video.replace(/.*?(\d+)/, '$1')
          ) {
            delete (eventsState[key])
          }
        })

        saveState('vimeo', eventsState)

        $(this).toggleClass('activity-box--populated-active')
      } else {
        // Save the watched state
        // {"https://player.vimeo.com/video/204836238":{"name":"progress","page":"https://chinacapable.org.nz/knowledge-transfer/carl-worker","url":"https://player.vimeo.com/video/204836238","value":76}}
        eventsState[video] = {
          name: 'progress',
          page: url,
          url: video,
          value: 100
        }

        saveState('vimeo', eventsState)

        $(this).toggleClass('activity-box--populated-active')
      }
    })
  }(window.jQuery))
})
