$(document).ready(function () {
  (function ($) {
    $('.js-kt-attendance-toggle').on('click', function () {
      var $ktAttendance = $('.js-kt-attendance')
      if ($ktAttendance.css('display') === 'none') {
        $(this).find('.js-kt-attendence-record-button-icon')
          .addClass('icon-up-open-big')
          .removeClass('icon-down-open-big')
      } else {
        $(this).find('.js-kt-attendence-record-button-icon')
          .addClass('icon-down-open-big')
          .removeClass('icon-up-open-big')
      }
      $ktAttendance.toggle(400)
      $(this).find('.js-kt-attendence-record-button')
        .toggleClass('button--triangle-below')
    })
  }(window.jQuery))
})
