$(document).ready(function () {
  (function ($) {
    $('.js-copy-to-clipboard').on('click', function (event) {
      event.stopPropagation()
      event.preventDefault()

      var value = $(this)
        .closest('.js-copy-to-clipboard-container')
        .find('.js-copy-to-clipboard-textarea')
        .val()

      // create a temporary textarea to allow us to copy to clipboard,
      // as the original textarea is disabled.
      var el = document.createElement('textarea')
      el.value = value
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    })
  }(window.jQuery))
})
