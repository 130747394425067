$(document).ready(function(){
    (function($) {
        // Show modal feedback form
        $('#js-feedback-link').on('click', function(e) {
            e.preventDefault();
            //$('.reveal-overlay').remove();
            var modal = new Foundation.Reveal($('#modalFeedbackForm'));

            // Register our handler to close the modal
            $('#modalFeedbackForm .modal__close')
                .off('click')
                .on('click', function() {
                    modal.close();
                });

            // Destroy our modal once it is closed.
            // If we don't do this, we end up with problems on repeated openings.
            $(window)
                .off('closed.zf.reveal')
                .on('closed.zf.reveal', function() {
                    // We need a set timeout so that we end up behind the 
                    // this.$element.hide call in the reveal plugin close method.
                    setTimeout(function() {
                        modal.destroy();
                        document.activeElement.blur();
                    }, 0)
                })

            // Open the modal
            $('#modalFeedbackForm .feedback-form__alert').addClass('hide');

            modal.open();
        });
    })(window.jQuery);
})
