$(document).ready(function() {
  ;function showPopup(url, windowName, win, w, h) {
    var y = win.top.outerHeight / 2 + win.top.screenY - (h / 2)
    var x = win.top.outerWidth / 2 + win.top.screenX - (w / 2)
    return win.open(
      url,
      windowName,
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, ' +
      'width=' + w + ', ' +
      'height=' + h + ', ' +
      'top=' + y + ', ' +
      'left=' + x
    )
  }

  (function($) {
    // 1050 x 590
    $('.js-show-scenario').on('click', function (event) {
      var link = $(this).data('link')
      if (link) {
        showPopup(link, 'Scenario', window, 1050, 590)
      }
    })
  })(window.jQuery)
})
